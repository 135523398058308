import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: "/home",
    },
    {
        path: '/userAgreement-en',
        name:'payment-en',
        component: () => import('../views/Home/userAgreement-en.vue'),
        meta: { title: '用户协议英文' }
    },
    {
        path: '/sharefitPrivacyPolicy-en',
        name:'payment-en',
        component: () => import('../views/Home/sharefitPrivacyPolicy-en.vue'),
        meta: { title: '用户隐私协议英文' }
    },
    {
        path: '/cloudStorage',
        name:'cloudStorage',
        component: () => import('../views/Home/cloudStorage.vue'),
        meta: { title: '云存储服务协议' }
    },
    {
        path: '/cloudStorage-en',
        name:'cloudStorage-en',
        component: () => import('../views/Home/cloudStorage-en.vue'),
        meta: { title: '云存储服务协议英文' }
    },
    {
        path: '/lotTerminal',
        name:'lotTerminal',
        component: () => import('../views/Home/lotTerminal.vue'),
        meta: { title: '物联网终端使用服务说明' }
    },
    {
        path: '/lotTerminal-en',
        name:'lotTerminal-en',
        component: () => import('../views/Home/lotTerminal-en.vue'),
        meta: { title: '物联网终端使用服务说明英文版' }
    },
    {
        path: '/payment',
        name:'payment',
        component: () => import('../views/Home/payment.vue'),
        meta: { title: '自动续费服务协议' }
    },
    {
        path: '/payment-en',
        name:'payment-en',
        component: () => import('../views/Home/payment-en.vue'),
        meta: { title: '自动续费服务协议英文' }
    },
    {
        path: '/privacy',
        name:'privacy',
        component: () => import('../views/Home/privacy.vue'),
        meta: { title: '隐私政策' }
    },
    {
        path: '/privacy-en',
        name:'privacy-en',
        component: () => import('../views/Home/privacy-en.vue'),
        meta: { title: '隐私政策英文' }
    },
    {
        path: '/policy',
        name:'policy',
        component: () => import('../views/Home/policy.vue'),
        meta: { title: '服务协议' }
    },
    {
        path: '/policy-en',
        name:'policy-en',
        component: () => import('../views/Home/policy-en.vue'),
        meta: { title: '服务协议英文' }
    },
    {
        path: '/',
        component: () => import("../components/common/Home"),
        children:[
            {
                path:"/home",
                component: () => import("../views/Home/Home"),
                meta:{title:"首页"}
            },
            {
                path:"/aboutWiodo",
                component: () => import("../views/onWiodo/on-wiodo"),
                meta:{title:"关于唯哆"}
            },
            {
                path:"/downLoad",
                component: () => import("../views/downLoad/downLoad"),
                meta:{title:"下载"}
            },
            {
                path:"/product/wiodoAgreement",
                component: () => import("../views/product/wiodoAgreement/wiodoAgreement"),
                meta:{title:"WiODO协议设备接入"}
            },
            {
                path:"/product/wiodoGB",
                component: () => import("../views/product/wiodoGB/wiodoGB"),
                meta:{title:"国标GB28181设备接入"}
            },
            {
                path:"/product/wiodoSDK",
                component: () => import("../views/product/wiodoSDK/wiodoSDK"),
                meta:{title:"SDK接入"}
            },
            {
                path:"/product/wiodoModel",
                component: () => import("../views/product/wiodoModel/wiodoModel"),
                meta:{title:"模组接入"}
            },
            {
                path:"/productService/cloudYunxinling",
                component: () => import("../views/product_service/cloud_yunxinling/cloud_yunxinling"),
                meta:{title:"云信令"}
            },
            {
                path:"/productService/cloudStorage",
                component: () => import("../views/product_service/cloud_storage/cloud_storage"),
                meta:{title:"云存储"}
            },
            {
                path:"/productService/servicesAI",
                component: () => import("../views/product_service/servicesAI/servicesAI"),
                meta:{title:"AI服务"}
            },
            {
                path:"/productService/cloudCall",
                component: () => import("../views/product_service/cloud_call/cloud_call"),
                meta:{title:"云通话"}
            },
            {
                path:"/productService/serviceIOT",
                component: () => import("../views/product_service/serviceIOT/serviceIOT"),
                meta:{title:"物联网卡服务"}
            },
            {
                path:"/productService/serviceCCTV",
                component: () => import("../views/product_service/serviceCCTV/serviceCCTV"),
                meta:{title:"视频监控服务"}
            },
            {
                path:"/customized/customizedAPP",
                component: () => import("../views/customized/customizedAPP/customizedAPP"),
                meta:{title:"APP SDK"}
            },
            {
                path:"/customized/customizedOEM",
                component: () => import("../views/customized/customizedOEM/customizedOEM"),
                meta:{title:"OEM APP"}
            },
            {
                path:"/customized/customizedGeneric",
                component: () => import("../views/customized/customizedGeneric/customizedGeneric"),
                meta:{title:"WiODO 智联公版APP"}
            },
            {
                path:"/dataAnalysis",
                component: () => import("../views/dataAnalysis/dataAnalysis"),
                meta:{title:"多维度数据分析"}
            },
            {
                path:"/operationService",
                component: () => import("../views/operationService/operationService"),
                meta:{title:"WiODO平台运营服务"}
            },
            {
                path:"/solution/smartRetirement",
                component: () => import("../views/solution/industry/smartRetirement"),
                meta:{title:"智慧养老"}
            },
            {
                path:"/solution/hardwareSolutions",
                component: () => import("../views/solution/smartPark/hardwareSolutions"),
                meta:{title:"智慧园区"}
            },
            {
                path:"/solution/smartStore",
                component: () => import("../views/solution/store/smartStore"),
                meta:{title:"智慧门店"}
            },
            {
                path:"/solution/hardware",
                component: () => import("../views/solution/hardware/wiodoHardware"),
                meta:{title:"智能硬件方案中心"}
            },
            {
                path:"/solution/hardware/:id",
                component: () => import("../views/solution/hardware/wiodoHardware"),
                meta:{title:"智能硬件方案中心"}
            },
            {
                path:"/solution/smartPet",
                component: () => import("../views/solution/smartPet/smartPet"),
                meta:{title:"智慧养宠"}
            },
            {
                path:"/wiodoPrice",
                component: () => import("../views/price/wiodoPrice"),
                meta:{title:"价格"}
            },
            {
                path:"/helpCenter",
                component: () => import("../views/support/helpCenter/helpCenter"),
                meta:{title:"帮助中心"}
            },
            {
                path:"/documentCenter",
                component: () => import("../views/support/documentCenter/documentCenter"),
                meta:{title:"文档中心"}
            },
            {
                path: "/productService/valueAddedServices",
                component: () => import("../views/product_service/valueAddedServices/valueAddedServices"),
                meta:{title:"唯哆其他增值服务"}
            },
            {
                path: "/intelligentOperation",
                component: () => import("../views/intelligentOperation/intelligentOperation"),
                meta:{title:"数据智能运营"}
            },
            {
                path:"/demo",
                component: () => import("../views/demo/demo"),
            }
        ],
    },
]

const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
        return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
