<template>
  <router-view></router-view>
</template>
<script>
import "./assets/css/element.less"
export default {
  components: {}
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 100%;
  background: none;
  margin: 0px
}
*::-webkit-scrollbar-track {
  background-color: #fff;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 3px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
button{
  cursor: pointer;
}
</style>
